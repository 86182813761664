<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1 :title="title" :text="text" :text1="text1"/>

    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <!-- Profile Sidebar -->
          <div class="col-lg-4 col-xl-3 theiaStickySidebar">
            <profilesidebar></profilesidebar>
          </div>
          <!-- / Profile Sidebar -->
          <div class="col-lg-8 col-xl-9">
            <div class="dashboard-header">
              <div class="header-back">
                <router-link :to="{ name: 'patient-plans' }" class="back-arrow">
                  <i class="fa-solid fa-arrow-left"></i>
                </router-link>
                <h3>Plans Details</h3>
              </div>
            </div>
            <div class="appointment-details-wrap">
              <!-- Plan Detail Card -->
              <div class="appointment-wrap appointment-detail-card">
                <ul class="detail-card-bottom-info">
                  <li>
                    <h6>Start Date</h6>
                    <span>{{ currentPlan.start_at }}</span>
                  </li>
                  <li>
                    <h6>End Date</h6>
                    <span>{{ currentPlan.end_at }}</span>
                  </li>
                  <li>
                    <div class="patinet-information">
                      <div class="patient-info">
                        <!--                        <h6><a href="#"><small>doctor: </small>{{ currentPlan.Doctor }}</a></h6>-->
                      </div>
                    </div>
                  </li>
                </ul>
                <hr/>
                <ul>
                  <li class="appointment-action">
                    <div class="detail-badge-info plans-details-btn-wrap">
                      <router-link
                          :to="{ name: 'patient-plans', query: { tab: 'morning' } }"
                          class="me-2 plans-details-btn plans-details-btn-morning"
                          :class="{ active: planType === 'morning' }"
                      >
                        <span class="badge">Morning</span>
                      </router-link>
                      <router-link
                          :to="{ name: 'patient-plans', query: { tab: 'noon' } }"
                          class="me-2 plans-details-btn plans-details-btn-noon"
                          :class="{ active: planType === 'noon' }"
                      >
                        <span class="badge">Noon</span>
                      </router-link>
                      <router-link
                          :to="{ name: 'patient-plans', query: { tab: 'evening' } }"
                          class="plans-details-btn plans-details-btn-evening"
                          :class="{ active: planType === 'evening' }"
                      >
                        <span class="badge">Evening</span>
                      </router-link>
                    </div>
                  </li>
                </ul>
                <!-- Navigation Buttons -->
<!--                <div :class="['plan-navigation', {'space-between': hasPreviousPlan}]">
                  <button
                      v-if="hasPreviousPlan"
                      class="nav-button prev-button"
                      @click="navigatePlan('previous')"
                  >
                    &lt;
                  </button>
                  <button
                      v-if="hasNextPlan"
                      class="nav-button next-button"
                      @click="navigatePlan('next')"
                  >
                    &gt;
                  </button>
                </div>-->
                <div v-if="currentPlan" class="detail-card-bottom-info">
                  <ul>
                    <li>
                      <div class="patinet-information mb-3">
                        <a href="#">
                          <img :src="currentPlan.image" alt="User Image"/>
                        </a>
                        <div class="patient-info">
                          <h3>{{ currentPlan.name }}</h3>
                          <div class="start-btn">
                            <router-link v-if="routerLinkTo" :to="routerLinkTo" class="btn btn-secondary"
                                         style="font-size:12px">
                              Details
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div v-if="currentPlan.general_instructions" class="patinet-information" style="display:block">
                        <h5>Wie es eingenommen werden soll</h5>
                        <p>{{ currentPlan.general_instructions }}</p>
                      </div>
                    </li>
                    <li>
                      <div v-if="currentPlan.description" class="patinet-information" style="display:block">
                        <h5>Hinweise</h5>
                        <p>{{ currentPlan.description }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /Plan Detail Card -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <doctor-footer></doctor-footer>

    <bottom-navbar/>

  </div>
</template>
<script setup>
import {watch, ref, computed, onMounted} from 'vue';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';

const store = useStore();
const route = useRoute();
const router = useRouter();

const routerLinkTo = ref(null);
const planId = ref(parseInt(route.params.id));
const currentPlan = ref({});

/*
const currentPlan = computed(() => {
  return store.getters['plans/getPlanById'](planId.value) || {};
});
*/

onMounted(async () => {

  if (store.getters['plans/plans'].length === 0) {
    await store.dispatch('plans/fetchPlans')
  }
  console.log('planId.value::::', planId.value)

  // Get the plan from the store after ensuring plans are loaded
  currentPlan.value = store.getters['plans/getPlanById'](planId.value);
  if (!currentPlan.value) {
    await store.dispatch('plans/fetchPlans')
    currentPlan.value = store.getters['plans/getPlanById'](planId.value);
  }
console.log('currentPlan.value::', currentPlan.value)
  if (!currentPlan.value) {
    console.error('Plan not found');
  }
});

const planType = computed(() => {
  const plan = currentPlan.value;
  console.log('plan typee:', plan)
  if (plan && plan.plan_time) {
    switch (plan.plan_time) {
      case 'Morning':
        return 'morning';
      case 'Noon':
        return 'noon';
      case 'Evening':
        return 'evening';
      default:
        return 'morning'; // Default plan type
    }
  }
  return 'morning'; // Default plan type if no plan time
});

// Navigation methods
const hasPreviousPlan = computed(() => {
  // Logic to determine if there is a previous plan
  return store.getters['plans/hasPreviousPlan'](planId.value);
});

const hasNextPlan = computed(() => {
  // Logic to determine if there is a next plan
  return store.getters['plans/hasNextPlan'](planId.value);
});

function capitalizeFirstLetterOfEachSentence(paragraph) {
  if (!paragraph) return ''; // handle empty or null values
  const sentences = paragraph.match(/[^.!?]+[.!?]*/g) || [];
  return sentences
      .map(sentence => sentence.charAt(0).toUpperCase() + sentence.slice(1).trim())
      .join(' ');
}

function navigatePlan(direction) {
  // navigate to the previous or next plan
  const id = store.getters['plans/getAdjacentPlanId'](planId.value, direction);
  if (id) {
    route.push({name: 'patient-morning-plan', params: {id}});
  }
}

watch(() => currentPlan.value?.item_id, (newId) => {
  if (newId) {
    routerLinkTo.value = {name: 'plan-item', params: {id: newId}};
  }
}, {immediate: true});
</script>
<style scoped>

.plans-details-btn {
  width: 200px;
  text-align: center;
  border-radius: 59px;
  text-decoration: none; /* Remove underline from links */
}

.plans-details-btn span {
  font-size: 16px !important;
  color: black !important;
}

.plans-details-btn-morning,
.plans-details-btn-noon,
.plans-details-btn-evening {
  background-color: transparent;
}

.plans-details-btn-morning.active,
.plans-details-btn-noon.active,
.plans-details-btn-evening.active {
  background-color: #0E82FD;
}

.plans-details-btn-morning.active span,
.plans-details-btn-noon.active span,
.plans-details-btn-evening.active span {
  color: white !important;
}

.nav-button {
  background-color: #0E82FD;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 20px;
}

.nav-button:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

.plan-navigation {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.plan-navigation.space-between {
  justify-content: space-between !important;
}
</style>

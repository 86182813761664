<template>
  <div
      v-if="isActive"
      class="tab-pane fade show active"
  >
    <!-- Plans List -->
    <div
        v-for="item in plans"
        :key="item.item_id"
        class="appointment-wrap"
    >
      <ul>
        <li>
          <div class="patinet-information">
            <router-link v-if="routerLinkTo" :to="routerLinkTo">
              <img :src="item.image" alt="Plan Image"/>
            </router-link>

            <div class="patient-info">
              <ul>
                <li>
                  <h6>
                    <router-link v-if="routerLinkTo" :to="routerLinkTo">
                      {{ item.name }}
                    </router-link>
                  </h6>
                </li>
                <li>
                  <span class="badge new-tag">{{ item.custom_dosage }} - {{ item.custom_instructions }}</span>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- /Plans List -->
    <!-- Handle no plans case -->
    <div v-if="plans.length === 0">
      <p>No Items available for this time.</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: true
    },
    plans: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      routerLinkTo: null,
    };
  },
  watch: {
    // Watch for changes in `plans`
    plans: {
      handler(newPlans) {
        // Find the first plan with an item_id, if available
        const currentPlan = newPlans.find(plan => plan.item_id);
        if (currentPlan && currentPlan.item_id) {
          this.routerLinkTo = { name: 'patient-morning-plan', params: { id: currentPlan.item_id } };
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>
<style scoped>
.patient-info ul li {
  text-transform: none !important; /* This will override the uppercase styling */
}
</style>